import React, { Component } from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import { loadReCaptcha } from 'react-recaptcha-google';

export default class App extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      grids: [],
      htmlpages: [],
      hidenav:false
    };
    this.handlergrids = this.handlergrids.bind(this);
    this.handlerhtml = this.handlerhtml.bind(this);
  }
  handlergrids (grids)
  {
    this.setState({
      grids: grids,
    });
  }
  handlerhtml (htmlpages)
  {
    this.setState({
      htmlpages: htmlpages
    });
  }
  componentDidMount() {
    loadReCaptcha();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let view = params.get('view');
    if(view){
    this.setState({
      hidenav:true
    });
    }
  }
  render ()
  {
    const { grids, htmlpages,hidenav } = this.state;
    return (
      <div >
      {hidenav ? null :<Header handlergrids={this.handlergrids} handlerhtml={this.handlerhtml} />}
        <Main grids={grids} htmlpages={htmlpages} />
      {  hidenav ? null: <Footer />}
      </div>
    );
  }
}
